const opinions = [
  {
    rate: 5,
    description: 'Motyleityle nie tylko tworzy interesujący i trafny content, ale również dba o to, by nasze artykuły były fachowo redagowane i publikowane na naszej stronie oraz w mediach społecznościowych. Dzięki ich kreatywności i umiejętnościom w obszarze marketingu, nasze kampanie są skuteczne i przyciągają uwagę naszej grupy docelowej.',
    author: 'Sebastian'
  },
  {
    rate: 5,
    description: 'Współpracuję z Dorotą i Szymonem z Motyleityle od wielu lat i mamy za sobą bardzo wiele różnorodnych projektów. Za każdym razem wyróżniają się pełnym profesjonalizmem, sprawną komunikacją, dogłębnym zrozumieniem potrzeb klienta, świetnymi pomysłami i sprawnym wdrożeniem założonych celów. Polecam ich usługi każdemu klientowi potrzebującemu wsparcia przy planowaniu strategii marketingowej, tworzeniu treści, prowadzeniu mediów społecznościowych, zorganizowaniu zdjęć, ilustracji, filmów.',
    author: 'Marcin'
  },
  {
    rate: 5,
    description: 'Generalnie nie mam zastrzeżeń, współpraca była ok. Jako że jesteście specami od pisania treści to napiszcie opinie w moim imieniu i dajcie mi do weryfikacji, ok?',
    author: 'Mateusz'
  },
  {
    rate: 5,
    description: 'Polecam firmę „Motyleityle” ze względu na profesjonalną obsługę, indywidualne podejście do każdego klienta oraz dokładność i solidność w realizacji projektów. Doceniam firmę „Motyleityle” ze względu na wyjątkową skrupulatność i dbanie o szczegóły w trakcie współpracy nad wydarzeniami „Dzień Zdrowia i Piękna” w kolejnych jego edycjach. Ogólna ocena 5/5. Polecam serdecznie firmę Motyleityle.',
    author: 'Lucyna'
  },
  {
    rate: 5,
    description: 'Bardzo profesjonalne podejście do klienta. Dorota dosłownie „czyta w moich myślach”- posty są zawsze lepsze niż ja mogłabym sobie wyobrazić . Zawsze pomocna i gotowa do działania. Nowe pomysły &#128161 przychodzą jej łatwo, bo zagłębia się w branżę, którą prezentuje. Jej kobiece oko pomaga w spójności zdjęć z postami. Wyłapie niewidzialne błędy i czasami doda „smaczek” dla oglądalności. Polecam',
    author: 'Ewa'
  }
]

export { opinions }
