<template>
<section id="workingMethods" class="working-methods-wrapper">
  <Header title="jak pracujemy"></Header>

  <div class="working-methods-content">
    <div>
      <h3>Spotkanie</h3>
      <p>Zawsze jesteśmy gotowi na spotkanie czy
        rozmowę. Jeśli do tego poznamy
        wcześniej Twoje potrzeby, odpowiednio
        się do tego przygotujemy i być może uda
        nam się Cię zaskoczyć.</p>
    </div>

    <div>
      <h3>Brief</h3>
      <p>Bez obaw - podeślemy Ci kilka prostych
        pytań. Chętnie możemy też wspólnie na
        nie odpowiedzieć.</p>
    </div>

    <div>
      <h3>Oferta</h3>
      <p>Jak już wszystkiego się dowiemy,
        otrzymasz szczegółową ofertę oraz
        propozycje warunków współpracy</p>
    </div>

    <div>
      <h3>Umowa</h3>
      <p>Pamięć bywa ulotna, więc podpiszemy
        sobie krótką, ale konkretną umowę.
        Potem już ogień!</p>
    </div>
  </div>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'

export default defineComponent({
  name: 'WorkingMethods',
  components: { Header }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/_mixins.scss";

section.working-methods-wrapper {
  background-image: url("@/assets/images/2.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: '';
    background-color: $color-light-green;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  & > div.working-methods-content {
    display: grid;
    grid-template-columns: 100%;
    position: relative;

    @include respond-to(min-width, 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to(min-width, 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }

    & > div {
      &:not(:first-child) {
        border-top: 2px dashed $color-white;
      }

      @include respond-to(min-width, 768px) {
        padding: 0 1rem 3rem 1rem;

        &:nth-child(2) {
          border-top: none;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-top: 2px dashed $color-white;
        }

        &:nth-child(2n - 1) {
          border-right: 2px dashed $color-white;
        }
      }

      @include respond-to(min-width, 1024px) {
        border-top: none !important;

        &:not(:last-child) {
          border-right: 3px dashed $color-white;
        }
      }

      & > h3 {
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
      }

      & > p {
        line-height: 1.25;
      }
    }
  }
}
</style>
