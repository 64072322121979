<template>
<h2>
  <i class="icon-butterfly" :class="{'green': $props.greenIcon, 'white': !$props.greenIcon}"></i>
  <span v-if="$props.title">{{$props.title}}</span>
</h2>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Header',
  props: {
    title: String,
    greenIcon: Boolean
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";
@import "@/assets/styles/_colors.scss";

h2 {
  margin: 0;
  height: $headerHeight;
  display: flex;
  align-items: center;
  position: relative;

  & > i {
    font-size: 3rem;
    display: flex;
    align-items: center;

    &.green {
      color: $color-light-green;
    }

    &.white {
      color: $color-white;
    }

    @include respond-to(min-width, 768px) {
      font-size: 4.5rem;
    }
  }

  & > img {
    height: 40px;
    width: auto;
    object-fit: contain;

    @include respond-to(min-width, 768px) {
      height: 60px;
    }
  }

  & > span {
    margin-left: 1rem;
    font-size: 2rem;

    @include respond-to(min-width, 768px) {
      font-size: 3.5rem;
    }
  }
}
</style>
