<template>
<section id="contact" class="contact-wrapper">
  <Header title="skontaktuj się z nami" :green-icon="true"></Header>

  <div class="contact-content">
    <div class="personal-contact first">
      <p class="name">Dorota</p>
      <a class="mail" href="mailto:dorota@motyleityle.pl">dorota@motyleityle.pl</a>
      <a class="phone" href="tel:531536133">531 536 133</a>
    </div>
    <div class="personal-contact second">
      <p class="name">Szymon</p>
      <a class="mail" href="mailto:szymon@motyleityle.pl">szymon@motyleityle.pl</a>
      <a class="phone" href="tel:690007749">690 007 749</a>
    </div>
    <div class="social-media">
      <a href="https://instagram.com/motyle_i_tyle_marketing" target="_blank">
        <i class="icon-instagram"></i>
        <span>motyle_i_tyle_marketing</span>
      </a>
    </div>
  </div>

  <footer>&copy; Motyleityle 2024</footer>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'

export default defineComponent({
  name: 'ContactView',
  components: { Header }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

section.contact-wrapper {
  $footerHeight: 50px;
  padding: 0 1rem;

  div.contact-content {
    height: calc(100dvh - $headerHeight - $footerHeight);
    display: grid;
    grid-gap: 3rem 1rem;
    grid-template-columns: 100%;
    grid-template-areas: "contact1"
                           "contact2"
                           "socialMedia";

    @include respond-to(min-width, 768px) {

      grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
      grid-template-areas: ". contact1 contact2 ."
                           ". socialMedia socialMedia .";
    }

    & > div.personal-contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.first {
        grid-area: contact1;
      }

      &.second {
        grid-area: contact2;
      }

      & > p {
        margin: 0;
      }

      & > a {
        text-decoration: none;
        color: $color-black;
      }

      & > p,
      & > a {
        font-size: 1.5rem;

        @include respond-to(min-width, 768px) {
          font-size: 2rem;
        }
      }
    }

    & > div.social-media {
      grid-area: socialMedia;

      & > a {
        font-size: 1.5rem;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-black;

        @include respond-to(min-width, 768px) {
          font-size: 2rem;
        }

        & > i {
          font-size: 2rem;
          display: flex;
          padding: 0.5rem;
          background-color: $color-light-green;
          color: $color-white;
          border-radius: 30%;
          margin-right: 1rem;

          @include respond-to(min-width, 768px) {
            font-size: 2.5rem;
            margin-right: 1rem;
          }
        }

        & > span {
          word-break: break-all;
          font-size: 1.75rem;
        }
      }
    }
  }

  & > footer {
    height: $footerHeight;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
