<template>
<section id="ourAdvantages" class="our-advantages-wrapper">
  <Header title="co nas wyróżnia" :green-icon="true"></Header>

  <div class="our-advantages-content">
    <div>
      <h3>Chwilę w tym siedzimy</h3>
      <p>Za nami kilkanaście lat doświadczenia.
        Zdobyliśmy je pracując dla firm handlowych
        i usługowych z różnych branż.</p>
    </div>
    <div>
      <h3>Szczegóły, szczególiki</h3>
      <p>Działamy w myśl zasady, że to szczegóły tworzą
        całość. Wykonując zlecenia się na
        najdrobniejszych detalach.</p>
    </div>
    <div>
      <h3>Lubimy się dzielić</h3>
      <p>Jesteśmy po to, by Ci pomóc i wesprzeć
        Twój biznes. Chętnie też dzielimy się swoją
        wiedzą i doświadczeniem</p>
    </div>

    <hr class="separator" />

    <div>
      <h3>Nie działamy na pół gwizdka</h3>
      <p>Nie dzielimy zleceń na ważne i mniej ważne. Do wszystkich
        podchodzimy z największą starannością.</p>
    </div>
    <div>
      <h3>Dotrzymujemy słowa</h3>
      <p>Robimy wszystko, by kontakt z nami był łatwy i
        przyjemny. Wszystkie ustalenia są jasne i przejrzyste.</p>
    </div>
  </div>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'

export default defineComponent({
  name: 'OurAdvantagesView',
  components: { Header }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

section.our-advantages-wrapper {
  display: flex;
  flex-direction: column;

  & > div.our-advantages-content {
    display: grid;
    grid-template-areas: "element_1"
                         "element_2"
                         "element_3"
                         "element_5"
                         "element_6";
    grid-gap: 1rem;
    margin: auto 0;

    @include respond-to(min-width, 768px) {
      //TODO DO IT BETTER?
      grid-template-areas: "element_1 element_1 element_1 . element_2 element_2 element_2 . element_3 element_3 element_3"
                           "separator separator separator separator separator separator separator separator separator separator separator"
                           ". . element_5 element_5 element_5 . element_6 element_6 element_6 . .";
      grid-gap: 2rem 1rem;
    }

    & > hr.separator {
      grid-area: separator;
      width: 100%;
      border: none;
      border-top: 3px dashed $color-light-green;
      display: none;

      @include respond-to(min-width, 768px) {
        display: block;
      }
    }

    & > div {
      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          grid-area: element_#{$i};
        }
      }

      &:not(:first-child) {
        border-top: 2px dashed $color-light-green;

        @include respond-to(min-width, 768px) {
          border-top: none;
        }
      }

      & > h3 {
        font-size: 2rem;

        @include respond-to(min-width, 768px) {
          margin-top: 0;
        }
      }

      & > p {
        line-height: 1.25;
      }
    }
  }
}
</style>
