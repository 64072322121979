<template>
<section id="offer" class="offer-wrapper">
  <Header title="oferta" :green-icon="true"></Header>

  <div class="offer-content">
    <div>
      <h3>Budowanie marki</h3>
      <p>Jeśli właśnie startujesz ze swoim
        biznesem, chcesz zająć się zarabianiem,
        masz mnóstwo spraw do załatwienia a nie
        masz głowy do tematów związanych z
        odpowiednim zaprezentowaniem swojej
        marki lub nie wiesz od czego zacząć –
        chętnie pomożemy Ci wystartować.
        Zajmiemy się tym, by od samego
        początku Twoja firma pozytywnie
        kojarzyła się Twoim Klientom.</p>

      <p>Przygotujemy projekt logotypu,
        koncepcję kolorystyczną a nawet
        dobierzemy dla Twojego biznesu
        odpowiednią czcionkę. Opracujemy
        strategię komunikacji, czyli to w jaki
        sposób prezentować markę w otoczeniu
        biznesowym. Dzięki temu od samego
        początku Twój nowy biznes będzie stawał
        się rozpoznawany i wyróżniał się na tle
        innych. W skrócie – sprawimy, by Twoja
        firma stała się unikatowa.</p>
    </div>

    <div>
      <h3>Branding / rebranding</h3>
      <p>Masz wrażenie, że Twoja firma stanęła w
        miejscu? Chciałbyś coś zmienić, by nie
        stać w miejscu? Nie masz pomysłu jak
        zostać zauważonym przez nowych
        Klientów? Nie masz środków, by
        zatrudnić pracownika, który na stałe
        zajmowałby się sprawami z tym
        związanymi? Dobrze trafiłeś! Zajmujemy
        się odświeżaniem spojrzenia na Twoją
        markę, tworząc nowe formy i sposoby
        komunikacji.</p>

      <p>Zmodyfikujemy za Ciebie spojrzenie na
        Twoją firmę oraz przygotujemy materiały,
        dzięki którym będziesz mógł w atrakcyjny
        sposób pokazać się Twoim Klientom. Z
        chęcią podejmiemy się przygotowania
        materiałów drukowanych – katalogów,
        folderów, ulotek czy plakatów. Zajmiemy
        się także wirtualną promocją firm –
        tworzymy nowe strony www, prezentacje
        marki i produktów oraz mailingi.</p>
    </div>

    <div>
      <h3>Copywriting</h3>
      <p>Nikt od Ciebie nie wie lepiej dlaczego
        oferta Twojej firmy jest najlepsza i
        najbardziej atrakcyjna – to pewne. Ale czy
        potrafisz to ubrać w słowa, by skutecznie
        dotrzeć do Klienta? My to potrafimy.
        Zajmujemy się tworzeniem treści oraz
        sloganów, które trafiają do Klientów tak,
        by wywołać oczekiwane, pozytywne
        emocje związane z Twoją ofertą.</p>
    </div>

    <div>
      <h3>Social Media</h3>
      <p>Wiesz jaką moc mają media
        społecznościowe i chcesz, aby Twoja
        firma dorównywała konkurencji?
        Planujesz zacząć lub zwiększyć sprzedaż
        przez wybrany kanał w mediach
        społecznościowych? To dobry ruch! A
        jeśli szukasz osób, które wiedzą jak to
        zrobić, posiadają sprzęt i narzędzia do
        tworzenia treści: wpisów, postów i rolek
        (krótkich, dynamicznych wideo), które
        trafią do Klientów – dobrze trafiłeś.</p>

      <p>Zajmujemy się kompleksowym
        przygotowaniem materiałów, które
        będziesz mógł udostępnić w mediach
        społecznościowych. A jeśli chciałbyś,
        byśmy również zajęli się obsługą kont –
        nie ma problemu.</p>
    </div>
  </div>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'

export default defineComponent({
  name: 'Offer',
  components: { Header }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

section.offer-wrapper {
  & > div.offer-content {
    height: calc(100% - (2 * $headerHeight));
    display: grid;
    grid-template-columns: 100%;

    @include respond-to(min-width, 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to(min-width, 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }

    & > div {
      padding: 0 1rem;

      &:not(:first-child) {
        border-top: 2px dashed $color-light-green;
      }

      @include respond-to(min-width, 768px) {
        &:nth-child(2) {
          border-top: none;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-top: 2px dashed $color-light-green;
        }

        &:nth-child(2n - 1) {
          border-right: 2px dashed $color-light-green;
        }
      }

      @include respond-to(min-width, 1024px) {
        border-top: none !important;

        &:not(:last-child) {
          border-right: 3px dashed $color-light-green;
        }
      }

      & > h3 {
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
      }

      & > p {
        line-height: 1.25;
      }
    }
  }
}
</style>
