import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6cd1c5ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h2", null, [
    _createElementVNode("i", {
      class: _normalizeClass(["icon-butterfly", {'green': _ctx.$props.greenIcon, 'white': !_ctx.$props.greenIcon}])
    }, null, 2),
    (_ctx.$props.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$props.title), 1))
      : _createCommentVNode("", true)
  ]))
}