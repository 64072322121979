<template>
<section id="aboutUs" class="about-us-wrapper">
  <Header title="o nas"></Header>

  <div class="about-us-content">
    <div>
      <h3>kim jesteśmy</h3>
      <div>
        <i class="icon-long-arrow-right"></i>
        <span>Sprytny duet marketingowców z wieloletnim doświadczeniem. Za nami miliony projektów, ale nadal nam się chce!</span>
      </div>
      <img src="@/assets/icons/who-we-are.svg" alt="Piktogram - kim jesteśmy" width="100" height="100" loading="lazy" />
    </div>

    <div>
      <h3>co robimy</h3>
      <div>
        <i class="icon-long-arrow-right"></i>
        <span>fajne rzeczy, którymi lubimy się dzielić</span>
      </div>
      <img src="@/assets/icons/what-we-do.svg" alt="Piktogram - co robimy" width="100" height="100" loading="lazy" />
    </div>

    <div>
      <h3>skąd jesteśmy</h3>
      <div>
        <i class="icon-long-arrow-right"></i>
        <span>jesteśmy stąd czyli z Dębicy:)</span>
      </div>
      <img src="@/assets/icons/where-we-are-from.svg" alt="Piktogram - skąd jesteśmy" width="100" height="100" loading="lazy" />
    </div>
  </div>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'

export default defineComponent({
  name: 'AboutUs',
  components: { Header }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/_motyle-icons.scss";
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

section.about-us-wrapper {
  background-image: url("@/assets/images/1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: '';
    background-color: $color-light-green;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  & > div.about-us-content {
    display: grid;
    grid-template-columns: 100%;
    height: calc(100% - (2 * $headerHeight));
    margin-bottom: 2rem;
    position: relative;

    @include respond-to(min-width, 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    & > div {
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-gap: 1rem 0;
      padding: 1rem 0;

      @include respond-to(min-width, 768px) {
        padding: 0 1rem;
        grid-gap: 0;
      }

      &:not(:last-child) {
        border-bottom: 2px dashed $color-white;

        @include respond-to(min-width, 768px) {
          border-bottom: none;
          border-right: 3px dashed $color-white;
        }
      }

      & > h3 {
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
        margin: 0;

        @include respond-to(min-width, 768px) {
          margin: 2rem 0;
        }
      }

      & > div {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 0 1rem;
        justify-self: center;
        align-items: center;

        & > i {
          $arrowBorderSize: 25px;
          width: $arrowBorderSize;
          height: $arrowBorderSize;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $color-black;
          border-radius: 50%;
        }

        & > span {
          font-size: 1rem;
          line-height: 1.25;

          @include respond-to(min-width, 768px) {
            font-size: 1.25rem;
          }
        }
      }

      & > img {
        width: 100%;
        height: auto;
        max-height: 100px;
        justify-self: center;

        @include respond-to(min-width, 768px) {
          max-height: 200px;
        }
      }
    }
  }
}
</style>
