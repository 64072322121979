<template>
  <i class="icon-butterfly following-icon"></i>
  <button class="hamburger-btn" aria-label="Otwarcie menu" @click="toggleNavbar">
    <i class="icon-bars"></i>
  </button>
  <Navbar :active="navbarStatus" @close-navbar="closeNavbar" @mousemove="onMouseMove"></Navbar>
  <main @mousemove="onMouseMove">
    <HomeView></HomeView>
    <AboutUs></AboutUs>
    <Offer></Offer>
    <WorkingMethods></WorkingMethods>
    <OurAdvantagesView></OurAdvantagesView>
    <OpinionsView></OpinionsView>
    <ContactView></ContactView>
  </main>
</template>

<script lang="ts">
import HomeView from '@/views/HomeView.vue'
import { defineComponent, ref } from 'vue'
import AboutUs from '@/views/AboutUsView.vue'
import Offer from '@/views/OfferView.vue'
import WorkingMethods from '@/views/WorkingMethodsView.vue'
import OurAdvantagesView from '@/views/OurAdvantagesView.vue'
import OpinionsView from '@/views/OpinionsView.vue'
import ContactView from '@/views/ContactView.vue'
import Navbar from '@/components/Navbar.vue'

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    ContactView,
    OpinionsView,
    OurAdvantagesView,
    WorkingMethods,
    Offer,
    AboutUs,
    HomeView
  },
  setup () {
    const navbarStatus = ref(false)

    const toggleNavbar = () => {
      navbarStatus.value = !navbarStatus.value
    }

    const closeNavbar = () => {
      navbarStatus.value = false
    }

    const onMouseMove = (event: any) => {
      const followingIcon: HTMLElement | null = document.querySelector('.following-icon');

      if (followingIcon) {
        const followingIconSizes = followingIcon.getBoundingClientRect();

        const posX = event.clientX - followingIconSizes.width / 2;
        const posY = event.clientY - followingIconSizes.height / 2;

        setTimeout(() => {
          followingIcon.style.left = `${posX}px`;
          followingIcon.style.top = `${posY}px`;
        }, 100)
      }
    }

    return {
      navbarStatus,
      toggleNavbar,
      closeNavbar,
      onMouseMove
    }
  }
})
</script>

<style lang="scss">
@import "@/assets/styles/_motyle-icons.scss";
@import "@/assets/styles/_mixins.scss";
@import "@/assets/styles/_colors.scss";

@font-face {
  font-family: "blogger-sans";
  src: url("@/assets/fonts/blogger-sans.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  & {
    font-family: 'blogger-sans', sans-serif;
  }

}

i.following-icon {
  position: fixed;
  font-size: 2rem;
  display: none;
  z-index: 25;
  pointer-events: none;
  left: -100px;

  @include respond-to(min-width, 768px) {
    display: block;
  }
}

button.hamburger-btn {
  position: fixed;
  z-index: 15;
  left: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @include respond-to(min-width, 768px) {
    left: 20px;
    top: 20px;
  }

  & > i {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: $color-black;
  }
}

main {
  width: 100%;
  position: relative;

  @include respond-to(min-width, 1024px) {
    //height: 100dvh;
  }

  & section {
    width: 100%;
    min-height: 100dvh;
    height: 100%;
    padding: 0 1rem 1rem 1rem;
    box-sizing: border-box;

    @include respond-to(min-width, 568px) {
      padding: 0 2rem 1rem 2rem;
    }

    @include respond-to(min-width, 768px) {
      padding: 0 3rem 2rem 3rem;
    }

    @include respond-to(min-width, 1366px) {
      padding: 0 5rem;
    }
  }
}
</style>
