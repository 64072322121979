<template>
<nav :class="{'active': $props.active}">
  <button class="close-navbar-btn" aria-label="Zamknięcie menu" @click="closeNavbar">
    <i class="icon-times"></i>
  </button>

  <a href="#" @click="closeNavbar" aria-label="Powrót do pierwszej strony">
    <i class="icon-butterfly"></i>
  </a>

  <ul>
    <li class="icon-butterfly">
      <a href="#aboutUs" @click="closeNavbar">o nas</a>
    </li>
    <li class="icon-butterfly">
      <a href="#offer" @click="closeNavbar">oferta</a>
    </li>
    <li class="icon-butterfly">
      <a href="#workingMethods" @click="closeNavbar">jak pracujemy</a>
    </li>
    <li class="icon-butterfly">
      <a href="#ourAdvantages" @click="closeNavbar">co nas wyróżnia</a>
    </li>
    <li class="icon-butterfly">
      <a href="#opinions" @click="closeNavbar">opinie</a>
    </li>
    <li class="icon-butterfly">
      <a href="#contact" @click="closeNavbar">kontakt</a>
    </li>
  </ul>
</nav>
</template>

<script lang="ts">
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
  name: 'Navbar',
  props: {
    active: Boolean
  },
  emits: ['close-navbar'],
  setup (props, { emit }) {
    const closeNavbar = () => {
      emit('close-navbar')
    }

    return {
      closeNavbar
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixins.scss";
@import "@/assets/styles/_colors.scss";

nav {
  background-color: $color-light-green;
  width: 100%;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 5px 1px $color-black;
  transform: translateX(calc(-100% - 5px));
  transition: 300ms ease-in-out;

  @include respond-to(min-width, 568px) {
    max-width: 300px;
  }

  &.active {
    transform: translateX(0);
    transition: 300ms ease-in-out;
  }

  & > button.close-navbar-btn {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;;
    border: none;
    outline: none;
    cursor: pointer;
    color: $color-black;

    & > i {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
    }
  }

  & > a {
    text-decoration: none;

    & > i {
      font-size: 4rem;
      color: $color-white;
    }
  }

  & > ul {
    width: 100%;
    list-style-type: none;
    padding: 0;

    & > li {
      padding: 0.5rem;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        border-top: 2px dashed $color-white;
      }

      &::before {
        color: $color-white;
      }

      & > a {
        margin-left: 1rem;
        text-decoration: none;
        color: $color-black;
        font-size: 1rem;

        @include respond-to(min-width, 768px) {
          font-size: 1.25rem;
        }
      }
    }
  }
}
</style>
