<template>
<section id="home" class="home-wrapper">
  <div class="logo">
    <i class="icon-butterfly"></i>
    <p>Motyleityle</p>
  </div>

  <h1>Cześć, robimy fajne rzeczy i chętnie się nimi dzielimy</h1>

  <a href="#aboutUs" aria-label="Przewiń do sekcji O nas">
    <i class="icon-long-arrow-down"></i>
  </a>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomeView',
  components: {}
})
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/_mixins.scss";

section.home-wrapper {
  background-color: $color-light-green;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div.logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
      color: $color-white;
      margin: 0;
      font-size: 1.25rem;

      @include respond-to(min-width, 768px) {
        font-size: 2rem;
      }
    }

    & > i {
      color: $color-white;
      font-size: 4rem;
      display: flex;
      align-items: center;

      @include respond-to(min-width, 768px) {
        font-size: 8rem;
      }
    }
  }

  & > h1 {
    font-size: 1.5rem;
    text-align: center;

    @include respond-to(min-width, 768px) {
      font-size: 3rem;
    }
  }

  & > a {
    --arrow-size: 40px;

    text-decoration: none;
    border-radius: 50%;
    border: 1px solid $color-black;
    width: var(--arrow-size);
    height: var(--arrow-size);
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to(min-width, 768px) {
      --arrow-size: 50px;
    }

    & > i {
      color: $color-black;
      font-size: 1.75rem;
      display: flex;
    }
  }
}
</style>
