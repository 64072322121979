<template>
<section id="opinions" class="opinions-wrapper">
  <Header title="opinie"></Header>

  <div class="opinions-content">
    <div class="opinions-carousel">
      <button @click="changeOpinion(-1)" aria-label="Poprzednia opinia">
        <i class="icon-long-arrow-left"></i>
      </button>
      <div class="opinions-carousel-content">
        <div>
          <div class="carousel-item" v-for="(opinion, index) in opinions" :key="'opinion_' + index">
            <div class="rate">
              <i v-for="rate in opinion.rate" :key="'rate_' + rate + '_' + index" class="icon-butterfly"></i>
            </div>
            <p class="description" v-html="opinion.description"></p>
            <strong class="author">{{opinion.author}}</strong>
            <p class="industry">{{opinion.industry}}</p>
          </div>
        </div>
      </div>
      <button @click="changeOpinion(1)" aria-label="Następna opinia">
        <i class="icon-long-arrow-right"></i>
      </button>
    </div>
  </div>
</section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Header from '@/components/Header.vue'
import { opinions } from '@/static/opinions'

export default defineComponent({
  name: 'OpinionsView',
  components: { Header },
  setup () {
    const currentItemIndex = ref(0)

    const changeOpinion = (way: number) => {
      const carousel = document.querySelector('div.opinions-carousel-content')

      if (carousel) {
        const carouselItem = carousel.querySelector('div.carousel-item')

        if (carouselItem) {
          const carouselItemWidth = carouselItem.clientWidth

          if (way > 0) {
            currentItemIndex.value = currentItemIndex.value + 1
          } else {
            currentItemIndex.value = currentItemIndex.value - 1
          }

          if (currentItemIndex.value > opinions.length - 1) {
            currentItemIndex.value = 0
          }

          if (currentItemIndex.value < 0) {
            currentItemIndex.value = opinions.length - 1
          }

          const offset = carouselItemWidth * currentItemIndex.value + (4 * currentItemIndex.value)

          carousel.scrollTo({
            left: offset,
            top: 0,
            behavior: 'smooth'
          })
        }
      }
    }

    return {
      opinions,
      changeOpinion
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

section.opinions-wrapper {
  --carousel-item-width: 60dvw;

  background-image: url("@/assets/images/3.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  @include respond-to(min-width, 768px) {
    --carousel-item-width: 500px;
  }

  &::before {
    content: '';
    background-color: $color-light-green;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  & > div.opinions-content {
    height: calc(100% - $headerHeight);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 2rem 0;

    @include respond-to(min-width, 1024px) {
      margin: 0;
    }

    & > div.opinions-carousel {
      display: flex;
      align-items: center;

      & > button {
        width: 35px;
        height: 35px;
        background-color: transparent;
        outline: none;
        border-radius: 50%;
        border: 1px solid $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color-black;

        & > i {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $color-white;
          font-size: 1.25rem;
        }
      }

      & > div.opinions-carousel-content {
        height: 100%;
        width: var(--carousel-item-width);
        overflow: hidden;
        margin: 0 0.5rem;

        @include respond-to(min-width, 568px) {
          margin: 0 1rem;
        }

        @include respond-to(min-width, 758px) {
          margin: 0 2rem;
        }

        & > div {
          display: flex;
          width: fit-content;

          & > div.carousel-item {
            width: var(--carousel-item-width);
            padding: 1rem;
            box-sizing: border-box;
            border-left: 2px dashed $color-white;
            border-right: 2px dashed $color-white;

            & > div.rate {
              display: flex;
              justify-content: center;

              & > i {
                color: $color-white;
                font-size: 1.875rem;

                @include respond-to(min-width, 568px) {
                  font-size: 3rem;
                }

                &:not(:first-child) {
                  margin-left: 0.5rem;

                  @include respond-to(min-width, 568px) {
                    margin-left: 1rem;
                  }
                }
              }
            }

            & > p.description,
            & > p.industry,
            & > strong.author {
              width: 100%;
              text-align: center;
            }

            & > p.description {
              line-height: 1.25;
            }

            & > strong.author {
              display: block;
            }

            & > p.industry {
              margin: 0.25rem 0;
            }
          }
        }
      }
    }
  }
}
</style>
